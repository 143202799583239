import React, { useState, useEffect } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Button, Container } from 'reactstrap';
import ContentWrapper from "../Layout/ContentWrapper";
import { getCortes, getCorteUser } from '../../services/Services';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert';
import { FILES_ENDPOINT } from '../../services/Services'

const CorteList = (props) => {

    const [data, setData] = useState([]);

    async function fetchInformation() {
        await getCortes().then((result) => {
            let sebo = [...result.data];
            sebo.map((item, index) => {
                sebo[index].montoTotal = (Number(item.totalEfectivo + item.totalTransferencia).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
            })
            setData(sebo)
        });
    }

    const notify = (title) => {
        toast(title, {
            type: "success",
            position: "bottom-center",
        });
    };
    
    const EditSection = props => (
        <div className="text-center py-2">
            <Button className="ml-1" color="primary" onClick={() => editInformation(props)}> <i className="far fa-eye"></i> </Button>
        </div>
    )

    const editInformation = ({ value }) => {
        props.history.push(`/corte/${value}`)
    }

    const rowGetter = (i) => data[i]

    const columns = [
        { key: 'name', name: 'Empleado' },
        { key: 'numNotas', name: '# Notas' },
        { key: 'totalEfectivo', name: 'Efectivo' },
        { key: 'totalTransferencia', name: 'Transferencias' },
        { key: 'montoTotal', name: 'Total' },
        { key: 'id', name: 'Acciones', formatter: EditSection, width: 150 }
    ];

    useEffect(() => {
        fetchInformation();
    }, [])

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div className="row">
                    <div className="col-md-8">Corte de Caja</div>
                    <div className="text-right col-md-4">
                    </div>
                </div>

            </div>

            <Container fluid className="shadow">
                <ReactDataGrid
                    columns={columns}
                    rowGetter={rowGetter}
                    rowsCount={data.length}
                    rowHeight={60}
                    minHeight={700} />
            </Container>
        </ContentWrapper>
    )
}

export default CorteList;