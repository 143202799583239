const Menu = [
    {
        heading: 'Navegación',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Dashboard',
        path: '/',
        icon: 'fas fa-home'
    },
    {
        name: 'Clientes',
        path: '/clients',
        icon: 'fas fa-users'
    },
    {
        name: 'Tiendas',
        path: '/tiendas',
        icon: 'fas fa-store'
    },
    {
        name: 'Proveedores',
        path: '/proveedores',
        icon: 'fas fa-truck-loading'
    },
    {
        name: 'Caja',
        icon: 'fas fa-money-bill-wave',
        submenu:[
            {
                name: 'Movimientos',
                path: '/movements',
            },
            {
                name: 'Corte',
                path: '/corte',
            },
            {
                name: 'Comisiones',
                path: '/comisiones',
            }
        ]
    },
    {
        name: 'Ord. Compra',
        path: '/oc-compra',
        icon: 'fas fa-tasks'
    },
    {
        name: 'Ord. Venta',
        path: '/oc-venta',
        icon: 'fas fa-tasks'
    },
    {
        name: 'Inventario',
        icon: 'fas fa-boxes',
        submenu:[
            {
                name: 'Productos',
                path: '/productos'
            },
            {
                name: 'Materiales',
                path: '/materiales'
            },
        ]
    },
    {
        name: 'Administración',
        icon: 'fas fa-users-cog',
        submenu: [
            {
                name: 'Cuentas',
                path: '/cuentas',
            },
            {
                name: 'Vendedores',
                path: '/vendedores',
            },
            {
                name: 'Cajeros',
                path: '/cajeros',
            },
            {
                name: 'Transportista',
                path: '/transportistas',
            },
            {
                name: 'Almacenistas',
                path: '/almacenistas'
            },
            {
                name: 'Administradores',
                path: '/administrators'
            },

        ]
    },
    
];

export default Menu;
