import React, { useState, useEffect } from 'react';
import ImageCropper from "../Common/ImageCropper";
import ContentWrapper from "../Layout/ContentWrapper";
import FormValidator from "../../store/reducers/FormValidator";
import { getBank, newBank, updateBank } from "../../services/Services";
import { toast } from "react-toastify";
import { withNamespaces } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    CardHeader,
} from "reactstrap";
import Swal from "sweetalert";

const BankForm = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [user, setUser] = useState(null);
    const [newForm, setnewForm] = useState({
        name: "",
        numero_cuenta: "",
        type_cuenta: "",
        banco: ""
    });

    //HACE LA VALIDACION DE LOS CAMPOS CADA QUE SE TECLEA
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === "checkbox" ? input.checked : input.value;
        const result = FormValidator.validate(input);

        setnewForm({
            ...newForm,
            [input.name]: value,
        });
    };

    //VERIFICA SI HAY ERRORES
    const hasErrors = (inputName, method) => {
        return (
            newForm &&
            newForm.errors &&
            newForm.errors[inputName] &&
            newForm.errors[inputName][method]
        );
    };

    //ENVIAR REQUEST
    const submitnewAdministrator = async (e) => {
        e.preventDefault();

        const form = e.target;
        const inputs = [...form.elements].filter((i) =>
            ["INPUT", "SELECT"].includes(i.nodeName)
        );
        const { errors } = FormValidator.bulkValidate(inputs);

        setnewForm({
            ...newForm
        });

        //Validate if is valid make api request
        if (!editMode) {
            await newBank(newForm)
                .then(async (response) => {
                    notify("Cuenta Creada exitosamente.");
                    props.history.goBack();
                })
                .catch((error) => {
                    Swal({
                        title: "¡Alerta!",
                        icon: "warning",
                        text: error.data,
                    });
                });
        } else {
            await updateBank(newForm, user.id)
                .then(() => {
                    //USUARIO MODIFICADO CORRECTAMENTE
                    notify("Cuenta modificada.");
                    props.history.goBack();
                })
                .catch((error) => {
                    Swal({
                        title: "Alert!",
                        icon: "warning",
                        text: error.data,
                    });
                });
        }
    };

    const notify = (title) => {
        toast(title, {
            type: "success",
            position: "bottom-center",
        });
    };

    //SE EJECUTA AL INICIAR
    useEffect(() => {

        async function getInformation() {
            await getBank(props.match.params.id)
                .then((result) => {
                    setUser(result.data);
                    setnewForm({
                        ...result.data
                    });
                })
                .catch((error) => {
                    Swal({
                        title: "Alert!",
                        icon: "warning",
                        text: error.data,
                    });
                });
        }

        if (!props.match.params.id) {
            // const user = JSON.parse(sessionStorage.getItem("USERSESSION"));
            // setUser(user);
        } else {
            setEditMode(true);
            getInformation();
        }

    }, []);

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div>
                    {!editMode ? "Registrar Cuenta" : "Actualizar Cuenta"}
                </div>
            </div>
            <Row>
                <Col xs={12}>
                    <Card className="p-3 shadow">
                        <CardBody>
                            <form
                                className="form-horizontal"
                                name="admin"
                                onSubmit={submitnewAdministrator}
                            >
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <div className="col-md-6">
                                                <label className="col-form-label">Nombre</label>
                                                <Input
                                                    onChange={validateOnChange}
                                                    type="text"
                                                    name="name"
                                                    invalid={hasErrors("admin", "name", "required")}
                                                    data-validate='["required"]'
                                                    value={newForm.name}
                                                />
                                                {hasErrors("admin", "name", "required") && (
                                                    <span className="invalid-feedback">
                                                        Campo requerido
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label className="col-form-label">No Cuenta</label>
                                                <Input
                                                    onChange={validateOnChange}
                                                    type="text"
                                                    name="numero_cuenta"
                                                    invalid={hasErrors("admin", "numero_cuenta", "required")}
                                                    data-validate='["required"]'
                                                    value={newForm.numero_cuenta}
                                                />
                                                {hasErrors("admin", "numero_cuenta", "required") && (
                                                    <span className="invalid-feedback">
                                                        Campo requerido
                                                    </span>
                                                )}
                                            </div>
                                        </FormGroup>
                                        <FormGroup row>
                                            <div className="col-md-6">
                                                <label className="col-form-label">Tipo Cuenta</label>
                                                <Input
                                                    onChange={validateOnChange}
                                                    type="text"
                                                    name="type_cuenta"
                                                    invalid={
                                                        hasErrors("admin", "type_cuenta", "required")
                                                    }
                                                    data-validate='["required"]'
                                                    value={newForm.type_cuenta}
                                                />
                                                {hasErrors("admin", "type_cuenta", "required") && (
                                                    <span className="invalid-feedback">
                                                        Campo requerido
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label className="col-form-label">Banco</label>
                                                <Input
                                                    onChange={validateOnChange}
                                                    type="text"
                                                    name="banco"
                                                    invalid={
                                                        hasErrors("admin", "banco", "required")
                                                    }
                                                    data-validate='["required"]'
                                                    value={newForm.banco}
                                                />
                                                {hasErrors("admin", "banco", "required") && (
                                                    <span className="invalid-feedback">
                                                        Campo Obligatorio
                                                    </span>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xl={12} className="d-flex flex-row justify-content-end">
                                        <Button type="submit" color="primary" className="shadow">
                                            {!editMode ? "Guardar" : "Actualizar"}
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </ContentWrapper>
    );
};

export default withNamespaces("translations")(BankForm);