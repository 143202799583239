import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* protectedroute component para rutas protegidas*/
import { ProtectedRoute } from './auth/protected.route';

// import Child_categories from './components/Child_categories.js/child_categories';
// import Banners from './components/Banners/Banners';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
import bankForm from './components/BankAccounts/bankForm';
import CorteList from './components/Corte/corteIndex';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Home = lazy(() => import('./components/home/Home'));

const Administrators = lazy(() => import('./components/Admins/Administrators'));
const AdministratorForm = lazy(() => import('./components/Admins/AdminForm'));

const StoreKeepers = lazy(() => import('./components/StoreKeepers/storekeepers'));
const storeKeeperForm = lazy(() => import('./components/StoreKeepers/storekeeperForm'));

const Clients = lazy(() => import('./components/Clients/Clients'));
const ClientForm = lazy(() => import('./components/Clients/ClientForm'));

const Vendors = lazy(() => import('./components/Vendedor/Vendedors'));
const VendorForm = lazy(() => import('./components/Vendedor/VendedorForm'));

const Cashiers = lazy(() => import('./components/Cashier/Cashiers'));
const CashierForm = lazy(() => import('./components/Cashier/CashierForm'));

const CarriersList = lazy(() => import('./components/Carriers/Carriers'));
const CarrierForm = lazy(() => import('./components/Carriers/CarrierForm'));

const ProviderList = lazy(() => import('./components/Providers/Providers'));
const ProviderForm = lazy(() => import('./components/Providers/ProviderForm'));

const StoreList = lazy(() => import('./components/Stores/Stores'));
const StoreForm = lazy(() => import('./components/Stores/StoreForm'));

const MaterialList = lazy(() => import('./components/Materials/Materials'));
const MaterialForm = lazy(() => import('./components/Materials/MaterialForm'));

const ProductsList = lazy(() => import('./components/Products/Products'));
const ProductForm = lazy(() => import('./components/Products/ProductForm'));
const ProductRecipe = lazy(() => import('./components/Products/ProductRecipe'));

const BuyOrdersList = lazy(() => import('./components/BuyOrders/BuyOrders'));
const CreateBuyOrder = lazy(() => import('./components/BuyOrders/CreateBuyOrder'));
const BuyOrderView = lazy(() => import('./components/BuyOrders/BuyOrderView'));
const payBuyOrder = lazy(() => import('./components/BuyOrders/PayBuyOrder'));

const SalesOrdersList = lazy(() => import('./components/SalesOrders/SaleOrders'));
const CreateSellOrder = lazy(() => import('./components/SalesOrders/CreateSellOrder'));
const SellOrderView = lazy(() => import('./components/SalesOrders/SellOrder_View'));

const MovementList = lazy(() => import('./components/Caja/CajaIndex'));

const BanksList = lazy(() => import('./components/BankAccounts/banks'));
const BankForm = lazy(() => import('./components/BankAccounts/bankForm'));

const corteList = lazy(() => import('./components/Corte/corteIndex'));
const corteView = lazy(() => import('./components/Corte/corteView'));

const comisionesList = lazy(() => import('./components/Comisiones/comisionesIndex'));
const comisionPay = lazy(() => import('./components/Comisiones/pagarComision'));


const Login = lazy(() => import('./pages/Login'));


//Lista de paginas que van fuera del layout wrapper
const listofPages = [
    '/login'
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };


    const animationName = 'rag-fadeIn'
    // console.log("refdconeriod wmdcsk", listofPages.indexOf(location.pathname))
    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div className="pages-container">
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <Route path="/login" component={waitFor(Login)} />
                                    {/* <Route exact path="/" component={waitFor(Home)} /> */}
                                    {/* <Route exact path="/banners" component={waitFor(Banners)} /> */}
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <ProtectedRoute exact path="/" component={waitFor(Home)} />

                                    <ProtectedRoute exact path="/administrators" component={waitFor(Administrators)} />
                                    <ProtectedRoute exact path="/administrators/new" component={waitFor(AdministratorForm)} />
                                    <ProtectedRoute exact path="/administrators/:id" component={waitFor(AdministratorForm)} />

                                    <ProtectedRoute exact path="/almacenistas" component={waitFor(StoreKeepers)} />
                                    <ProtectedRoute exact path="/almacenistas/new" component={waitFor(storeKeeperForm)} />
                                    <ProtectedRoute exact path="/almacenistas/:id" component={waitFor(storeKeeperForm)} />

                                    <ProtectedRoute exact path="/clients" component={waitFor(Clients)} />
                                    <ProtectedRoute exact path="/clients/new" component={waitFor(ClientForm)} />
                                    <ProtectedRoute exact path="/clients/:id" component={waitFor(ClientForm)} />

                                    <ProtectedRoute exact path="/vendedores" component={waitFor(Vendors)} />
                                    <ProtectedRoute exact path="/vendedores/new" component={waitFor(VendorForm)} />
                                    <ProtectedRoute exact path="/vendedores/:id" component={waitFor(VendorForm)} />

                                    <ProtectedRoute exact path="/cajeros" component={waitFor(Cashiers)} />
                                    <ProtectedRoute exact path="/cajeros/new" component={waitFor(CashierForm)} />
                                    <ProtectedRoute exact path="/cajeros/:id" component={waitFor(CashierForm)} />

                                    <ProtectedRoute exact path="/transportistas" component={waitFor(CarriersList)} />
                                    <ProtectedRoute exact path="/transportistas/new" component={waitFor(CarrierForm)} />
                                    <ProtectedRoute exact path="/transportistas/:id" component={waitFor(CarrierForm)} />

                                    <ProtectedRoute exact path="/proveedores" component={waitFor(ProviderList)} />
                                    <ProtectedRoute exact path="/proveedores/new" component={waitFor(ProviderForm)} />
                                    <ProtectedRoute exact path="/proveedores/:id" component={waitFor(ProviderForm)} />

                                    <ProtectedRoute exact path="/tiendas" component={waitFor(StoreList)} />
                                    <ProtectedRoute exact path="/tiendas/new" component={waitFor(StoreForm)} />
                                    <ProtectedRoute exact path="/tiendas/:id" component={waitFor(StoreForm)} />

                                    <ProtectedRoute exact path="/materiales" component={waitFor(MaterialList)} />
                                    <ProtectedRoute exact path="/materiales/new" component={waitFor(MaterialForm)} />
                                    <ProtectedRoute exact path="/materiales/:id" component={waitFor(MaterialForm)} />

                                    <ProtectedRoute exact path="/productos" component={waitFor(ProductsList)} />
                                    <ProtectedRoute exact path="/productos/new" component={waitFor(ProductForm)} />
                                    <ProtectedRoute exact path="/productos/:id" component={waitFor(ProductForm)} />
                                    <ProtectedRoute exact path="/receta-producto/:id" component={waitFor(ProductRecipe)} />

                                    <ProtectedRoute exact path="/oc-compra" component={waitFor(BuyOrdersList)} />
                                    <ProtectedRoute exact path="/oc-compra/new" component={waitFor(CreateBuyOrder)} />
                                    <ProtectedRoute exact path="/oc-compra/:id" component={waitFor(BuyOrderView)} />
                                    <ProtectedRoute exact path="/pay-ordencompra/:id" component={waitFor(payBuyOrder)} />

                                    <ProtectedRoute exact path="/oc-venta" component={waitFor(SalesOrdersList)} />
                                    <ProtectedRoute exact path="/oc-venta/new" component={waitFor(CreateSellOrder)} />
                                    <ProtectedRoute exact path="/oc-venta/:id" component={waitFor(SellOrderView)} />

                                    <ProtectedRoute exact path="/movements" component={waitFor(MovementList)} />

                                    <ProtectedRoute exact path="/cuentas" component={waitFor(BanksList)} />
                                    <ProtectedRoute exact path="/cuentas/new" component={waitFor(BankForm)} />
                                    <ProtectedRoute exact path="/cuentas/:id" component={waitFor(BankForm)} />

                                    <ProtectedRoute exact path="/corte" component={waitFor(corteList)} />
                                    <ProtectedRoute exact path="/corte/:id" component={waitFor(corteView)} />

                                    <ProtectedRoute exact path="/comisiones" component={waitFor(comisionesList)} />
                                    <ProtectedRoute exact path="/comisiones/:id" component={waitFor(comisionPay)} />
                                    
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
                <ToastContainer />
            </Base>
        )
    }
}

export default withRouter(Routes);
