import axios from "axios";

// Aura EndPoints
// export const ENDPOINT = "http://137.184.176.112/api/v1";
// export const FILES_ENDPOINT = "http://137.184.176.112";

// Aura EndPoints Domain
export const ENDPOINT = "https://api.auraagua.mx/api/v1";
export const FILES_ENDPOINT = "https://api.auraagua.mx";

//Aura endpoints local
//export const ENDPOINT = "http://localhost:7000/api/v1";
//export const FILES_ENDPOINT = "http://localhost:7000";

const sessionToken = () => (
    JSON.parse(sessionStorage.getItem("USERSESSION")) ? JSON.parse(sessionStorage.getItem("USERSESSION")).accessToken : false
);

const requestFunction = async (method, url, body) => {
    const headers = sessionToken() ? { 'authorization': sessionToken(), 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' }
    const options = {
        method,
        url,
        data: JSON.stringify(body || {}),
        headers
    }

    try {
        const response = await axios(options);
        console.log("Resp ->", response.data)
        return { error: false, data: response.data };
    } catch (error) {
        // console.log("respon del servidor error",error)
        let errorMessages = [];
        const errors = error.response.data;
        for (let error in errors) {
            if (Array.isArray(errors[error])) {
                errorMessages.push(`${errors[error][0]}, `);
            } else {
                errorMessages.push(`${errors[error]}, `);
            }
        }
        throw { error: true, data: "".concat(error.response.data.message) }
    }
};


export const login = async ({ email, password }) => {
    return await requestFunction('post', `${ENDPOINT}/signin`, { email, password });

}

export const logout = async () => {
    const session = JSON.parse(sessionStorage.getItem("USERSESSION"));
    return await requestFunction('delete', `${ENDPOINT}/logout`);
}


// Administradores
export const getAdministrators = async () => {
    return await requestFunction('get', `${ENDPOINT}/admins`);
}
export const getAdministrator = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/admins/${id}`);
}

export const updateAdministrator = async (admin, id) => {
    return await requestFunction('put', `${ENDPOINT}/admins/${id}`, admin)
}
export const newAdministrator = async (admin) => {
    return await requestFunction('post', `${ENDPOINT}/admins`, admin)
}
export const deleteAdmin = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/admins/${id}`)
}

// Almacenista
export const getStorekeepers = async () => {
    return await requestFunction('get', `${ENDPOINT}/storekeepers`);
}
export const getStorekeeper = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/storekeepers/${id}`);
}
export const updateStorekeeper = async (admin, id) => {
    return await requestFunction('put', `${ENDPOINT}/storekeepers/${id}`, admin)
}
export const newStorekeeper = async (admin) => {
    return await requestFunction('post', `${ENDPOINT}/storekeepers`, admin)
}
export const deleteStorekeepers = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/storekeepers/${id}`)
}

// Clientes
export const getClients = async () => {
    return await requestFunction('get', `${ENDPOINT}/clients`);
}
export const getClient = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/clients/${id}`);
}
export const updateClient = async (newForm, id) => {
    return await requestFunction('put', `${ENDPOINT}/clients/${id}`, newForm)
}
export const newClient = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/clients`, newForm)
}
export const deleteClient = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/clients/${id}`)
}

// Vendedores
export const getVendors = async () => {
    return await requestFunction('get', `${ENDPOINT}/sellers`);
}
export const getVendor = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/sellers/${id}`);
}
export const updateVendor = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/sellers/${id}`, newForm)
}
export const newVendor = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/sellers`, newForm)
}
export const deleteVendor = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/sellers/${id}`)
}

// Cajeros
export const getCashiers = async () => {
    return await requestFunction('get', `${ENDPOINT}/cashiers`);
}
export const getCashier = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/cashiers/${id}`);
}
export const updateCashier = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/cashiers/${id}`, newForm)
}
export const newCashier = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/cashiers`, newForm)
}
export const deleteCashier = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/cashiers/${id}`)
}

// Transportistas
export const getCarriers = async () => {
    return await requestFunction('get', `${ENDPOINT}/carriers`);
}
export const getCarrier = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/carriers/${id}`);
}
export const updateCarrier = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/carriers/${id}`, newForm)
}
export const newCarrier = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/carriers`, newForm)
}
export const deleteCarrier = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/carriers/${id}`)
}

// Proveedores
export const getProviders = async () => {
    return await requestFunction('get', `${ENDPOINT}/providers`);
}
export const getProvider = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/providers/${id}`);
}
export const updateProvider = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/providers/${id}`, newForm)
}
export const newProvider = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/providers`, newForm)
}
export const deleteProvider = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/providers/${id}`)
}

// Tiendas
export const getStores = async () => {
    return await requestFunction('get', `${ENDPOINT}/stores`);
}
export const getStoresCliente = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/stores-clients/${id}`);
}
export const getStore = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/stores/${id}`);
}
export const updateStore = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/stores/${id}`, newForm)
}
export const newStore = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/stores`, newForm)
}
export const deleteStore = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/stores/${id}`)
}

// Materiales para productos
export const getMaterials = async () => {
    return await requestFunction('get', `${ENDPOINT}/materials`);
}
export const getMaterial = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/materials/${id}`);
}
export const updateMaterialCantidad = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/materials/${id}`, newForm)
}
export const updateMaterial = async (newForm, id) => {
    return await requestFunction('put', `${ENDPOINT}/materials/${id}`, newForm)
}
export const newMaterial = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/materials`, newForm)
}
export const deleteMaterial = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/materials/${id}`)
}

// Productos
export const getProducts = async () => {
    return await requestFunction('get', `${ENDPOINT}/products`);
}
export const getProduct = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/products/${id}`);
}
export const updateProduct = async (newForm, id) => {
    return await requestFunction('put', `${ENDPOINT}/products/${id}`, newForm)
}
export const updateProductCantidad = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/products/${id}`, newForm)
}
export const newProduct = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/products`, newForm)
}
export const deleteProduct = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/products/${id}`)
}

// Crear Receta a Producto
export const getProductRecipe = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/recipe-products/${id}`);
}
export const newProductRecipe = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/recipe-products`, newForm)
}
export const updateProductRecipe = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/recipe-products/${id}`, newForm)
}
export const deleteProductRecipe = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/recipe-products/${id}`)
}
export const deleteProductRecipeMaterial = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/recipe-products-material/${id}`)
}

// Ordenes de Compra
export const getBuyOrders = async () => {
    return await requestFunction('get', `${ENDPOINT}/purchase-orders`);
}
export const getBuyOrder = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/purchase-orders/${id}`);
}
export const updateBuyOrder = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/purchase-orders/${id}`, newForm)
}
export const newBuyOrder = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/purchase-orders`, newForm)
}
export const deleteBuyOrder = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/purchase-orders/${id}`)
}
export const payBuyOrder = async (newInfo, ordenCompra) => {
    return await requestFunction('post', `${ENDPOINT}/pay-purchase-orders/${ordenCompra}`, newInfo);
}

// Ordenes de Venta
export const getSellOrders = async () => {
    return await requestFunction('get', `${ENDPOINT}/sales-orders`);
}
export const getSellOrder = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/sales-orders/${id}`);
}
export const updateSellOrder = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/sales-orders/${id}`, newForm)
}
export const newSellOrder = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/sales-orders`, newForm)
}
export const deleteSellOrder = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/sales-orders/${id}`)
}

//notas de venta
export const updateSaleNote = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/sales-notes/${id}`, newForm);
}

// Caja Movimientos
export const getMovements = async () => {
    return await requestFunction('get', `${ENDPOINT}/cash`);
}
export const getMovementsByDate = async (data) => {
    return await requestFunction('post', `${ENDPOINT}/cash-date`, data);
}
export const getSaldos = async () => {
    return await requestFunction('get', `${ENDPOINT}/cash-values`);
}
export const getSaldosDate = async (data) => {
    return await requestFunction('post', `${ENDPOINT}/cash-values-date`, data);
}
export const getMovement = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/cash/${id}`);
}
export const updateMovement = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/cash/${id}`, newForm)
}
export const newMovement = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/cash`, newForm)
}
export const deleteMovement = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/cash/${id}`)
}

// Cuentas de Banco
export const getBanks = async () => {
    return await requestFunction('get', `${ENDPOINT}/cuentas`);
}
export const deleteBank = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}/cuentas/${id}`)
}
export const getBank = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/cuentas/${id}`);
}
export const updateBank = async (newForm, id) => {
    return await requestFunction('patch', `${ENDPOINT}/cuentas/${id}`, newForm)
}
export const newBank = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/cuentas`, newForm)
}

// Cuentas de Vendedores con Saldo
export const getCortes = async () => {
    return await requestFunction('get', `${ENDPOINT}/sales-notes-vendedores`);
}
export const getCorteUser = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/sales-notes-vendedores/${id}`);
}
export const payNote = async (id) => {
    return await requestFunction('post', `${ENDPOINT}/cash-sell-note/${id}`);
}

// Comisiones a vendedores por pagar
export const getAllComisiones = async () => {
    return await requestFunction('get', `${ENDPOINT}/pagar-comisiones-vendedores`);
}
export const getComision = async (id) => {
    return await requestFunction('get', `${ENDPOINT}/comisiones-vendedores/${id}`);
}
export const payComision = async (id) => {
    return await requestFunction('post', `${ENDPOINT}/cash-comision/${id}`);
}

// Coordenadas
export const getCoordsAprox = async (address) => {
    console.log('address => ', address)
    return await axios.get(`https://nominatim.openstreetmap.org/search?q=${address}&format=json&polygon=1&addressdetails=1`);
}

export const autoComplete = async (address) => {
    return await axios.get(`https://nominatim.openstreetmap.org/search?format=geojson&limit=5&q=${encodeURI(address)}`);
};

export const newCVS = async (newForm) => {
    return await requestFunction('post', `${ENDPOINT}/download-csv`, newForm)
}