import React, { useState, useEffect } from 'react';
import { FILES_ENDPOINT } from '../../services/Services';
import ReactCrop from 'react-image-crop';
import { Row, Col, Input, Container, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropper = ({ type, user, imageGetter }) => {
    console.log('user', user)

    const [modal, setModal] = useState(false);
    const [src, selectFile] = useState(null);
    const [image, setImage] = useState(null);
    const [url, setUrl] = useState(null);
    const [crop, setCrop] = useState({
        aspect: type === 'avatar' ? 1 / 1 : (type === 'banner' || type === 'img') ? 16 / 9 : 1 / 1,
        unit: 'px',
        width: type === 'avatar' ? 400 : type === 'banner' ? 400 : type === 'img' ? 500 : 500,
        height: type === 'avatar' ? 400 : type === 'banner' ? 100 : type === 'img' ? 500 : 400,
        ruleOfThirds: true
    });

    //PARA ABRIR Y CERRAR MODAL DEL CROP
    const toggle = () => setModal(!modal);

    //PARA SELECCIONAR IMAGEN Y SETEARLA EN EL CROPPER
    const handleFileChange = e => {
        //VALIDA SI EL USUARIO NO SELECCIONO ALGUN ARCHIVO
        if (e.target.value.length > 0) {
            console.log(e.target.files);
            const file = e.target.files[0];
            // selectFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                // Use a regex to remove data url part
                const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
                setImage(reader.result);
                imageGetter(base64String, type);

            };
            reader.readAsDataURL(file);
        }
    }

    //CROPPER PARA IMAGEN
    const cropHandler = (e) => {
        setCrop(e)
    }

    //PONER IMAGENES DEFAULT DEL USUARIO (LAS QUE YA TIENE)
    const setDefaultImages = () => {
        type === 'avatar' ? setImage(`${FILES_ENDPOINT}${user.avatar}`)
            : type === 'banner' ? setImage(`${FILES_ENDPOINT}${user.img}`)
                : type === 'gallery' ? setImage(`${FILES_ENDPOINT}${user.path_image}`)
                    : type === 'image' ? setImage(`${FILES_ENDPOINT}${user.Image}`)
                        : type === 'img_location' ? setImage(`${FILES_ENDPOINT}${user.img_location}`)
                            : type === 'img' ? setImage(`${FILES_ENDPOINT}${user.Constancia}`)
                                : type === 'logo' ? setImage(`${FILES_ENDPOINT}${user[type]}`)
                                    : type === 'image_mision' ? setImage(`${FILES_ENDPOINT}${user[type]}`)
                                        : type === 'image_historia' ? setImage(`${FILES_ENDPOINT}${user[type]}`)
                                            : setImage(`${FILES_ENDPOINT}${user.img_map}`);
    }

    useEffect(() => {
        user && setDefaultImages();
    }, [user]);

    //OBTIENE LA IMAGEN YA RECORTADA
    const getCroppedImg = () => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        const base64image = canvas.toDataURL('image/png');
        const base64 = cleanBase64(base64image);
        setImage(base64image);
        imageGetter(base64, type);
        toggle();
    }

    const cleanBase64 = (base64) => {
        return base64.split(',')[1];
    }

    //CIERRA MODAL AL CANCELAR Y RESETEA VALORES
    const closeModal = () => {
        toggle();
        setDefaultImages();
        selectFile(null);
    }

    function isBase64Pdf(str) {
        return typeof str === 'string' && /^data:application\/pdf;base64,/.test(str);
    }

    function openPDFInNewTab(image) {
        // Decodificar los datos base64 en un ArrayBuffer.
        const binaryData = atob(image);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Crear un Blob a partir del ArrayBuffer.
        const blob = new Blob([uint8Array], { type: 'application/pdf' });

        // Crear una URL a partir del Blob y abrirlo en una nueva ventana.
        const url = URL.createObjectURL(blob);
        console.log('url => ', url)
        setUrl(url);
    }

    return (
        <Container>
            {
                type === 'avatar' ? (
                    <div className="d-flex flex-column">
                        <h4>Avatar</h4>
                        <img src={image} className={type === 'avatar' ? 'img-fluid shadow mb-3' : 'img-fluid shadow mb-3'} width={200} heigth={200} alt="avatar" />
                        <label htmlFor="inputImage" title="Upload image file" className="btn btn-info btn-upload shadow">
                            <Input id="inputImage" name="file" type="file" accept="image/*" className="sr-only" onChange={handleFileChange} />
                            <span title="Import image with Blob URLs" className="docs-tooltip">
                                Seleccione imagen
                            </span>
                        </label>
                    </div>
                ) : (type === 'banner' || type === 'img') ? (
                    <div className="d-flex flex-column">
                        <h3>Constancia</h3>
                        {image ? (
                            isBase64Pdf(image) ? (
                                <p className="text-center mb-2">
                                    Archivo cargado con exito
                                </p>
                            ) : image.endsWith(".pdf") ? (
                                // Si `image` termina con ".pdf", muestra el enlace para abrir el PDF
                                <a className="text-center mb-2"
                                    href={image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Abrir PDF
                                </a>
                            ) : (
                                // Si no es un PDF codificado en base64 y no termina con ".pdf", muestra una etiqueta de imagen
                                <img
                                    src={image}
                                    className={type === 'avatar' ? 'img-fluid rounded-circle shadow mb-3' : 'img-fluid rounded shadow mb-3'}
                                    width={650}
                                    height={350}
                                    alt="banner"
                                />
                            )
                        ) : (
                            // Si no hay contenido, muestra un mensaje o algo adecuado
                            <span className="text-center mb-2" >No se ha cargado ningún archivo.</span>
                        )}
                        <label htmlFor="inputBanner" title="Upload image file" className="btn btn-info btn-upload shadow">
                            <Input
                                id="inputBanner"
                                name="file"
                                type="file"
                                accept="image/*, application/pdf"
                                className="sr-only"
                                onChange={handleFileChange}
                            />
                            <span title="Import image with Blob URLs" className="docs-tooltip">
                                Seleccione imagen/pdf
                            </span>
                        </label>
                    </div>

                ) : (
                    <div className="d-flex flex-column">

                        <h3>{type === 'image' ? 'Imagen' : type === 'img_location' ? 'Lugar' : 'Imagen'}</h3>
                        <img src={image} className={type === 'avatar' ? 'img-fluid rounded-circle shadow mb-3' : 'img-fluid rounded shadow mb-3'} width={400} heigth={400} alt={type} />
                        <label htmlFor={type} title="Upload image file" className="btn btn-info btn-upload shadow">
                            <Input id={type} name="file" type="file" accept="image/*" className="sr-only" onChange={handleFileChange} />
                            <span title="Import image with Blob URLs" className="docs-tooltip">
                                Seleccione imagen
                            </span>
                        </label>

                    </div>
                )
            }
            {/* Crop Modal */}
            {/* <Modal isOpen={modal} toggle={toggle} size='lg' >
                <ModalBody className="text-center">
                    {src && (
                        <ReactCrop className="img-fluid" locked={true} src={src} onImageLoaded={setImage} crop={crop} onChange={cropHandler} />
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="shadow" onClick={getCroppedImg}>Aceptar</Button>
                    <Button color="danger" className="shadow" onClick={closeModal}>Cancelar</Button>
                </ModalFooter>
            </Modal> */}
            {/* Crop Modal End*/}
        </Container>
    )
}

export default ImageCropper;